<template>
  <div
    class="d-sm-flex d-block mt-6 mb-3 justify-center"
    v-if="!isAuthenticated"
  >
    <div>
      <v-img
        max-width="200"
        src="@/assets/img/PeepsProne.svg"
        class="mx-auto"
      ></v-img>
    </div>
    <div>
      <h2 class="display-1 peeps-title text-center">
        <slot name="title">Don't have a Peeps Account?</slot>
      </h2>
      <div class="text-center mt-4">
        <v-btn to="/signup" color="success" class="text-capitalize" large
          ><slot name="button">Create A Free Account</slot></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media screen and (max-width: 960px) {
  .peeps-title {
    font-size: 1.3rem !important;
  }
}
</style>
