<template>
  <v-main>
    <v-container>
      <v-card outlined class="peeps-card">
        <peeps-above></peeps-above>
        <v-container class="peeps--font container">
          <h1 class="display-1">
            <span>About Peeps</span>
          </h1>
          <div>
            <p>
              Hi! My name is <i>Peeps</i>, and I’m here to help you learn about
              your environment in Truro, what affects it, how it can affect your
              health, and how you can help preserve it.
            </p>
            <p>
              I earned a dual major in economics and the environment, very
              useful for living in Truro with its abundant but fragile natural
              resources. I have a lot of information to share and I'm eager to
              learn even more from you!
            </p>
          </div>
          <div>
            <croak />
          </div>
          <div>
            <p>
              In the meantime, please enjoy using my easy-to-use app, which I’m
              calling the <i>Peeps Portal</i>, because it gives you a path to
              recording, analyzing, managing, and drawing insights about several
              of the aspects of owning a home in Truro. Thanks to my generous
              non- profit supporters, my app is totally free to everyone in
              Truro.
            </p>
            <p>
              With it you can keep track of your property taxes and get a sense
              of where they are heading. That helps you in understanding the
              Town’s budget and how to assess various expenditures that might be
              proposed.
            </p>
            <p>
              Living in a rural town like Truro also means you probably have a
              private well. You’ll want to have your well water tested every
              year or so, and my Peeps Portal will give you a convenient place
              to record those results too. And when you do, I can share with you
              what those test results mean and what you might do to stay
              healthy.
            </p>
            <p>
              I’ve got several other features in the works for you, and I’ll be
              announcing them when they are closer to being ready. All with the
              goal of making it easier for you to manage your Truro property
              over the years.
            </p>
            <p>
              I’m sure you value your privacy as much as I do, so I’ve taken
              steps to make sure your information is secure and protected by a
              password only you have access to. You can read more about my
              privacy protections
              <router-link to="/about/privacy">here</router-link>.
            </p>
            <p>
              I hope you’ll take advantage of all my work, especially as it’s
              totally free.
              <span v-if="!isAuthenticated"
                >Please join me and
                <router-link to="/signup">
                  click here to sign up now!</router-link
                ></span
              >
            </p>
          </div>
          <div v-if="!isAuthenticated">
            <p>
              So go ahead and signup – it’s easy to create your very own free
              Peeps account – just click this button to get started.
            </p>
            <create-free-account>
              <template v-slot:title><span></span></template>
            </create-free-account>
          </div>
          <div>
            <p>Have a great day — <i>Peeps</i></p>
          </div>
        </v-container>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PeepsAbove from '@/components/PeepsAbove'
import CreateFreeAccount from '@/components/CreateFreeAccount'
import user from '@/mixins/user'
import Croak from '@/components/Croak'
export default {
  metaInfo() {
    return {
      title: 'About Peeps'
    }
  },
  components: {
    PeepsAbove,
    CreateFreeAccount,
    Croak
  },
  mixins: [user]
}
</script>

<style scoped>
.boiling-title {
  font-weight: 100;
}
.container {
  font-size: 20px;
}
.frog-in-pot {
  margin-top: -30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
